import React, { useRef, useEffect, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './PrintForm';
import { ComponentToPrintSlip } from './InterviewForm';
import StdStatus from './stdStatus';
import { useParams } from 'react-router-dom';

export default function RegPdfReport( ) {
  // const { mId } = match.params; // Extract mId from URL params
  const { mId } = useParams();
  const registrationFormRef = useRef(null); 
  const interviewSlipRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const [showStdStatus, setShowStdStatus] = useState(false);

  const toggleStdStatus = () => {
    setShowStdStatus(!showStdStatus);
  };

  // Fetch the form data based on mId when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/PrintForm/${mId}`);
        if (response.ok) {
          const data = await response.json();
          // Check if data is an array and contains at least one object
          if (Array.isArray(data) && data.length > 0) {
            const firstDataObject = data[0];
            setFormData(firstDataObject); // Set the fetched form data
          } else {
            console.error('No data found in the response');
          }
        } else {
          console.error('Error fetching form data');
        }
      } catch (error) {
        console.error('Error fetching form data:', error);
      }
    };
    if (mId) {
      fetchData(); // Fetch data when mId is available
    }
  }, [mId]);
  // console.log('mId:', mId);

  

  return (
    <>
      <div className='container my-5'>
        <h1 className='text-center'>Welcome to Fatehpur Public School.</h1>
        {formData && (
        <h6 className='text-center'>Dear Student <span className='text-uppercase'>{formData.name}</span>.</h6>
      )}
      </div>
        <div className='d-flex justify-content-evenly m-view my-5'>
      <div>
      {formData && (
        <ReactToPrint
          trigger={() => <a href="#A" className='btn btn-success'>Print Registration Form!</a>}
          content={() => registrationFormRef.current}
        />
      )}
      <div style={{ display: 'none' }}>
      {formData && <ComponentToPrint ref={registrationFormRef} mId={mId} />}
      </div>
    </div>
      <div>
      {formData && (
        <ReactToPrint
          trigger={() => <a href="#B" className='btn btn-danger'>Print InterView Slip!</a>}
          content={() => interviewSlipRef.current}
        />
      )}
      <div style={{ display: 'none' }}>
      {formData && <ComponentToPrintSlip ref={interviewSlipRef} mId={mId} />}
      </div>
    </div>
    <div>
      <button className='btn btn-primary' onClick={toggleStdStatus}> Check Admission Status </button>
      {showStdStatus && formData && <StdStatus data={formData} />}
    </div>
    </div>
    <footer className="fixed-bottom bg-light text-center p-2">
        <p><span>Note: </span>
          Click on Print Button To Save Report As PDF
          <br />
          IN Mobile, Click On Three Dots At Top Right Of The Screen
        </p>
      </footer>
    </>
  );
}
