import React, { useState, useEffect } from 'react';

export default function ImageGallery() {

    const [images, setImages] = useState([]);

    useEffect(() => {
      fetch(`/api/SchlImgGallery`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setImages(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
  }, []); 

  return (
<>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-container ">
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className="container my-5 py-5 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded">
<div className="csection-title">
  <h2>Explore Our Captivating Image Gallery</h2>
  <p>A Visual Journey Through the Vibrant Life at Apex</p>
</div>
    <div className="row">
    {images.map((image, index) => 
    <div key={index} className={`col-md-4   ${index === 0 ? 'active' : ''}`}>
        <div className="image-containers shadow p-3 mb-5 bg-white rounded">
            <img src={`/api/Admin/Uploads/SchlGlryImg/${image.gllryImgUrl}?timestamp=${new Date().getTime()}`} className="d-block w-100 h-auto img" alt={image.gllryImgTitle} />
        </div>
    </div>
    )}
    </div>
</div>
    </>
  )
}
