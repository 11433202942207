import React from 'react'


export default function DocDnld(props) {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>Download Documents</h2>
        </div>
        </div>
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Download School Documents</h2>
  <p>-------------------------------</p>
</div>
<div className="timing-table">
  <div className="timing-row">
    <div className="timing-cell">Documents Name</div>
    <div className="timing-cell">Download Documents</div>
    {/* <div className="timing-cell">Documents3</div> */}
  </div>
  {props.documents && props.documents.map((document, index)  =>
  <div key={index} className={`timing-row ${ index === 0 ? 'active' : ''}`}>
    <div className="timing-cell">{document.docTitle}</div>
     <div className="timing-cell"><a href={`/viewDocDnld/${document.pdfName}`} target='_blank' rel='noreferrer'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z"/>
    </svg></a></div> 
    {/* <div className="timing-cell">{document.docTitle}</div> */}
  </div>
    )}
</div>

</div>
    </>
  )
}
