import React from 'react'
import smart from '../Images/Physics B1-L1-042.jpg'
import chem from '../Images/Chemistry B2-L2-043.jpg'

export default function Laboratory() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>School Laboratory</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>EXPLORE OUR STATE-OF-THE-ART LABORATORIES</h2>
  <p>Igniting Curiosity and Learning Through Hands-On Experiments</p>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1400px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
        <p className="">
        "Welcome to a world of discovery and exploration at Apex International School! Our commitment to modern education is beautifully manifested through our state-of-the-art laboratories, where learning goes beyond textbooks and takes on a vibrant, hands-on approach.
        </p>
        <p>
        <h6 style={{ color: '#bc6c25', fontFamily: 'Poppins'}}>Physics Lab:</h6>
        Our physics lab offers cutting-edge equipment and resources to enhance student learning through practical experimentation. Safety measures are paramount, with robust safety protocols and supervised environments ensuring a secure and enriching experience for all.
        </p>
        <p>
        <h6 style={{ color: '#bc6c25', fontFamily: 'Poppins'}}>Chemistry Lab:</h6>
        Our chemistry lab boasts state-of-the-art equipment and facilities, facilitating hands-on learning experiences for students. With safety as our top priority, our lab is equipped with proper ventilation systems and safety protocols to ensure a secure environment for experimentation and exploration.
        </p>
        <p>
        <h6 style={{ color: '#bc6c25', fontFamily: 'Poppins'}}>Bio Lab:</h6>
        In our biology lab, students engage in immersive learning experiences with access to modern equipment and resources. Emphasizing safety, our lab is equipped with advanced tools and protocols to ensure a secure environment for hands-on exploration and experimentation, fostering a deeper understanding of biological concepts.
        </p>
        <p className="card-text"><small className="text-body-secondary">Apex International School</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containerss shadow  my-5 bg-white rounded">
      <img src={smart} className="img-fluid rounded-start" alt="..." />
      <img src={chem} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
</div>
    </>
  )
}
