import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import React from 'react';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useParams } from 'react-router-dom';
export default function ViePdf(props) {
  const { PdfName } = useParams(); 

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTab: defaultTabs => {
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      // defaultTabs[1] // Thumbnails tab
    },
  });
    
  return (
    <>
     {/* <Worker  workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      {selectedViewPdf && selectedViewPdf.PdfName && (
      <div className="pdf-viewer">
      <div className="pdf-viewer-content">
        <Viewer fileUrl={`/api/Admin/Uploads/TransferCertificate/${selectedViewPdf.PdfName}`} plugins={[defaultLayoutPlugin]} />
      </div>
      </div>
)}
      </Worker> */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={`/api/Admin/Uploads/TransferCertificates/${PdfName}`}
            plugins={[defaultLayoutPluginInstance]}
            defaultScale={0.5}
          />
      </Worker>
    </>
  )
}
