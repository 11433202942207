import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/LogIn.css';

export default function LogIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`/api/LogIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        document.cookie = "loginType=" + data.loginType;
        localStorage.setItem("isLoggedIn", "true"); 
        navigate('/Admin/AdminHome');
        window.location.reload();
      } else {
        setErrorMessage('Username or password is incorrect'); 
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
       <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
            <form className="d-flex" role="search">
            <Link to="/" className="btn btn-outline-success">
              Home
            </Link>
            </form>
        </div>
    </nav>
      <div className="admin-login-container">
        <div className="admin-login-form">
          <h2 className="text-center">Admin Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                autoComplete="current-username"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="text-center">
            <button type="submit" className="btn btn-primary btn-lg button" value="Login">
            Login
          </button>
            </div>
          </form>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </>
  );
}
