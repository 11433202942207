import React from 'react'
import { Link } from 'react-router-dom';
import facebook from '../Images/logo/facebook.png'
import youtube from '../Images/logo/youtube.png'

export default function Footer() {
  return (
    <>
    <footer className="pt-5 mt-4 container text-white" style={{ maxWidth: '100%', margin:'0px',  backgroundColor:'#bc6c25' }}>
    <div className="row" style={{ backgroundColor:'#fefae0'}}>
      <div className="col-9 offset-md-1 col-md-2 mb-3 pt-2 ">
        <h3 className='text-color'>Quick Links</h3>
        <ul className="nav flex-column ">
          <li className="nav-item mb-2 "><Link to="/" className="nav-link p-0 text-dark">Home</Link></li>
          <li className="nav-item mb-2"><Link to="/AboutUs" className="nav-link p-0 text-dark">About Us</Link></li>
          <li className="nav-item mb-2"><Link to="/SmartClass" className="nav-link p-0 text-dark">Facilities</Link></li>
          <li className="nav-item mb-2"><Link to="/ContactUs" className="nav-link p-0 text-dark">Contact Us</Link></li>
        </ul>
      </div>
      <div className="col-9 offset-md-1 col-md-4 mb-3 pt-2">
        <h3 className='text-color'>Reach Us</h3>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 text-dark">FIROZPUR, KAISERGANJ BAHRAICH, UTTAR PRADESH – 271901</Link></li>
          <li className="nav-item mb-2"><Link to="tel:++918400314141" className="nav-link p-0 text-dark">(+91) 840 031 4141</Link></li>
          <li className="nav-item mb-2"><Link to="mailto:apexinternationalschool@gmail.com" className="nav-link p-0 text-dark">apexinternationalschool@gmail.com</Link></li>
          <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">Monday - Saturday, 08:00AM - 02:00 PM</Link></li>
        </ul>
      </div>
      <div className="col-md-4  mb-3 pt-2">
          <h5 className='text-color'>Apex International School</h5>
          <p className='text-dark'>Reaching New Heights, Together.</p>
          <div className="col-6 col-md-5 mb-3 pt-2 ">
          <ul className="nav flex-row">
            <li className="nav-item mb-2"><Link to='https://www.facebook.com/apexinternational2018?mibextid=ZbWKwL'><img src={facebook} width="32" height="32" className="rounded-circle me-2" alt='footer-logo'/></Link></li>
            <li className="nav-item mb-2"><Link to='https://www.youtube.com/@noorhussainsapexinternatio226'><img src={youtube} width="32" height="32" className="rounded-circle me-2" alt='footer-logo'/></Link></li>
          </ul>
      </div>
      </div>
    </div>
    <div className="d-flex flex-column flex-sm-row justify-content-between pt-2 " style={{ backgroundColor:'#bc6c25'}}>
      <p className='text-white text-center'>© 2023 Apex International School, Inc. All rights reserved.</p>
      <p className='text-white text-center'><Link to='http://rayaninfosolutions.com/' className='nav-link'>Designed by: Rayan Info Solutions</Link></p>
    </div>
  </footer>
      <div className="whatsappWidget widgetPosition-Bottom-Right">
      <Link to='https://api.whatsapp.com/send?phone=+918400314141'><img src="https://image1.jdomni.in/jdomni_email/whatsapp_popup_2011271203.png?output-format=webp" alt='a' className="whatsappIcon" /></Link>
      </div>
    </>
  )
}
