import React from 'react'

export default function SchlTiming() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>School Timing</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Apex School Timing</h2>
  <p>Punctuality: Your Pathway to Academic Excellence</p>
</div>
<div className="timing-table">
  <div className="timing-row">
    <div className="timing-cell">Class</div>
    <div className="timing-cell">Summer Timings</div>
    <div className="timing-cell">Winter Timings</div>
  </div>
  <div className="timing-row">
    <div className="timing-cell">Senior (IV-VIII)</div>
    <div className="timing-cell">7:00 AM - 1:30 PM</div>
    <div className="timing-cell">7:30 AM - 2:00 PM</div>
  </div>
  <div className="timing-row">
    <div className="timing-cell">Junior (NUR. - III)</div>
    <div className="timing-cell">8:00 AM - 12:30 PM</div>
    <div className="timing-cell">8:30 AM - 1:00 PM</div>
  </div>
</div>

</div>   
    </>
  )
}
