import React from 'react';

const StdStatus = ({ data }) => {
    let statusText = 'Awaiting Status';
  let statusClass = ''; // Default class for the status text

  if (data.status === 'UnSelected') {
    statusText = 'Not Selected';
    statusClass = 'not-selected';
  } else if (data.status === 'Selected') {
    statusText = 'Selected';
    statusClass = 'selected';
  } else if (data.status === 'NA') {
    statusText = 'Awaiting Status';
    statusClass = 'awaiting-status';
  }

  return (
    <div className='mt-3'>
      <h3>Status Information</h3>
      <p>Name: {data.name}</p>
      <p>Status: <strong className={statusClass}>{statusText}</strong></p>
    </div>
  );
};

export default StdStatus;
