import React, { useState, useEffect } from 'react';
import '../CSS/Admin_Home.css';

export default function ViewEnquiryData() {
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
  
    const handlefromDateChange = (e) => {
      setFromDate(e.target.value);
    }
    const handletoDateChange = (e) => {
      setToDate(e.target.value);
    }
    const handleSubmit = (e) => {
      e.preventDefault(); // Prevents the form from submitting
      
      // Use the fromDate and toDate to construct the URL for API request
      const apiUrl = `/api/ViewEnquiryData?fromDate=${fromDate}&toDate=${toDate}`;
      console.log('API URL:', apiUrl);
      fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setData(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle the error
        });
    };    
  
    useEffect(() => {
      fetch(`/api/ViewEnquiryData`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setData(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
    }, []);      
  
    return (
      <>
        <div className='container' >
       <div className='shadow-sm p-3 bg-white rounded mb-3'>
       <h6 className='text-center'>Data Enquiries</h6>
        <form className='row  mx-0 d-flex justify-content-around' onSubmit={handleSubmit} >
          <div className='col-md-2'>
            <label  className='form-label' htmlFor="title">From Date:</label>
          </div>
          <div className='col-md-3 mb-2'>
            <input  className='form-input' type="Date" id="fromDate" name='fromDate' value={fromDate}  onChange={handlefromDateChange} required />
          </div>
          <div className='col-md-2'>
            <label  className='form-label' htmlFor="image">To Date:</label>
          </div>
          <div className='col-md-3 mb-2'>
            <input  className='form-input' type="Date"  name="toDate" id="toDate"  value={toDate} onChange={handletoDateChange} required />
          </div>
          <div className='col-md-2'>
          <button className='form-submit' type="submit">Apply Filter</button>
          </div>
        </form>
       </div>
        <div className='d-felx'>
        <div className='shadow mb-5 rounded scrollOnXaxis' style={{ maxHeight: '411px', overflowY: 'auto' }}>
        <table className='table  table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Date</th>
              <th>Client Name</th>
              <th>E-mail</th>
              <th>Mobile No</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody className='text-center'>
          {data.map((item, index) => (
              <tr key={index} className='text-center'>
                <td>{item.enquiryDate && item.enquiryDate.slice(0, 10)}</td>
                <td>{item.clientName}</td>
                <td>{item.clientEmail}</td>
                <td>{item.clientNo}</td>
                <td>{item.clientMssg}</td>
                {/* <td><p className='badge badge-success-lighten' onClick={() => handleResult(item.RFIID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/><path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/></svg></p></td> */}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
        </div>
      </>
    );
}
