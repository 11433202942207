import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import user from '../Images/logo/user.png'
import '../CSS/TopNotice.css'


export default function TopNotice() {

    const [scrollingText, setScrollingText] = useState([]);

      useEffect(() => {
        fetchOptions();
      }, []);

      // const fetchOptions = async () => {
      //   try {
      //     const response = await fetch(`${baseURL}/api/topNotice`);
      //     if (response.ok) {
      //       const data = await response.json();
      //       if (data && Array.isArray(data) && data.length > 0) {
      //         const filteredOptions = data.filter(option => option.newsTitle !== null).map(option => ({
      //           name: option.newsTitle,
      //         }));
      //         setScrollingText(filteredOptions);
      //       } else {
      //         console.error('Invalid or empty data received');
      //       }
      //     } else {
      //       console.error('Error fetching options');
      //     }
      //   } catch (error) {
      //     console.error('Error fetching options:', error);
      //   }
      // };      
    
      const fetchOptions = async () => {
        try {
          const response = await fetch(`/api/NoticeForm`); 
          if (response.ok) {
            const data = await response.json();
            // console.log(data)
            const filteredOptions = data.recordset.filter(option => option.newsTitle !== null).map(option=> ({
              name: option.newsTitle,
            }));
            setScrollingText(filteredOptions);
          //   console.log(filteredOptions) 
          } else {
            console.error('Error fetching options');
          }
        } catch (error) {
            console.error('Error fetching options:', error);
          }
      };
   
    return (
        <>
        <div className="top-notice">
            <div className="non-scrolling-text">Latest News</div>
            <div className="scrolling-container">
            {scrollingText.length > 0 && scrollingText.map((newsTitle, index) => (
                <span className="scrolling-text text-white" key={index}>
                  {newsTitle.name}</span>
                ))}
            </div>
            <div className='d-flex justify-content-end bgColor'>
            <Link to="../Admin/LogIn" className="text-white mx-2">LogIn<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/><path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/></svg></Link>
            </div>
        </div>
      </>
    )
}
