import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';

export default function AdmStatus() {
    const [data, setData] = useState([]);
    const [name, setName] = useState('')
    const [dob, setDob] = useState('')
    const [fathermobNo, setFathermobNo] = useState('')
    const [searched, setSearched] = useState(false);
    const navigate = useNavigate();

    const handlename = (e) =>{
        setName(e.target.value)
      }
    const handledob = (e) =>{
      setDob(e.target.value)
    }
    const handleFatherMno = (e) =>{
      setFathermobNo(e.target.value)
    }
    const handleResult = (mId) => {
      navigate(`/RegPdfReport/${mId}`)
    };
      const handlereset = () =>{
        setName('')
        setDob('')
        setFathermobNo('')
        setData([]);
      }

      const handleSearch = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch(`/api/AdmStatus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name,
              dob,
              fathermobNo,
            }),
          });
          if (response.ok) {
            const data = await response.json();
            setData(data);
            console.log(data);
            setSearched(true);
          } else {
            console.error('Error fetching data');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  return (
    <>
<div className='container d-felx my-5 align-items-center form-inline'>
      <h1 className='text-center heady'>Check Status</h1>
        <div className='head'>
            <h3>Enter Your Details To Print Registration form</h3>
        </div>
        <div className='parent-container my-3'>
        <form method="GET" className='row g-3 my-1'>
        <div className='row'>
          <div className='col-md-4'>
            <div className="form-group row">
              <label className='col-sm-4 col-form-label text-end left-text' htmlFor="title">Student Name<span className='span'>*</span>:</label>
              <div className='col-sm-8'>
                <input className='form-control' type="text" id="name" value={name} onChange={handlename} required />
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className="form-group row">
              <label className='col-sm-5 col-form-label text-end left-text' htmlFor="dob">Date Of Birth<span className='span'>*</span>:</label>
              <div className='col-sm-7'>
                <input className='form-control' type="Date" id="dob" value={dob} onChange={handledob} required placeholder="mm/dd/yyyy" />
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className="form-group row">
              <label className='col-sm-5 col-form-label text-end left-text' htmlFor="dob">Father Mobile No<span className='span'>*</span>:</label>
              <div className='col-sm-7'>
                <input className='form-control' type="text" id="fathermobNo" value={fathermobNo} onChange={handleFatherMno} required />
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3 offset-md-2'>
          <div className="form-group row justify-content-center ">
            <button type='button' className='btn btn-success btn-sm btn-width' onClick={handleSearch}>Search</button>
          </div>
        </div>
        <div className='col-md-3 offset-md-2'>
          <div className="form-group row justify-content-center">
            <button type='button' className='btn btn-danger btn-sm btn-width' onClick={handlereset}>Reset</button>
          </div>
        </div>
    </form>
      </div>
      </div>     
        {searched && data && data.length > 0 ? (
        <div className='container'>
          <h2>Search Results</h2>
          <table className='table table-nowrap mb-0  scrollable-table mp-0'>
          <thead className='table-primary'>
            <tr>
              <th>Student Name</th>
              <th>Mother Name</th>
              <th>DOB</th>
              <th>Click</th>
            </tr>
          </thead>
          <tbody className='text-center'>
          {data.map((item, index) => (
              <tr key={index} className='text-center'>
                <td>{item.name}</td>
                <td>{item.motherName}</td>
                <td>{item.dob.slice(0, 10)}</td>
                <td><p className='badge badge-success-lighten' onClick={() => handleResult(item.mId)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/><path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/></svg></p></td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
         ) : (
          <p>No search results found</p> 
        )}
    </>
  )
}
