import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import logo from '../Images/logo/777663.png'

export const ComponentToPrint = React.forwardRef((props, ref) => {
    const { mId } = props; 
    const [Data, setData] = useState([]);
    const [formData, setFormData] = useState({}); 
    const [loading, setLoading] = useState(true);
    // console.log('ComponentToPrint props:', props);


    useEffect(() => {
        fetch(`/api/PrintForm/${mId}`)
        .then((response) => response.json())
        .then(formDataArray => {
            // Access the first item in the array (assuming there's only one item)
            const formData = formDataArray[0];
            // console.log(formData);
            setFormData(formData);
            setLoading(false); // Set loading to false when formData is available
        })
        .then(() => {
            // Fetch Data (assuming Data is an array)
            fetch(`/api/PrintForm/${mId}`) // Replace with the correct API endpoint for Data
            .then((response) => response.json())
            .then(DataArray => {
                // console.log(DataArray);
                setData(DataArray);
                setLoading(false); // Set loading to false when Data is available
            })
            .catch((error) => {
                console.error('Error fetching Data:', error);
                setLoading(false);
            });
        })   
    }, [mId]);     

      const inputStyle = {
        border: 'none',
        // Add any other custom styles you need
      };
      const labelStyle = {
        fontWeight: 'bold'
        // Add any other custom styles you need
      };
      const qrCodeValue = `https://klvdiagnostics.in/RegPdfReport/${mId}`;

    return (
      <div ref={ref} style={{margin:'20px 40px'}}>
        {/* <p className='font-weight-bold'>FORM NO. FPS ${formData.mId || ''}/PN</p> */}
        <hr className='my-3'/>
        <div className='form-info mb-4'>
          <div className='img-container mt-5 mx-1' style={{ width:'100px'}}>
          <img src={logo} className="img-thumbnail" alt="..." />
          </div>
        <div className='text-container mt-5'>
        <h4 className='text-center'>ADMISSION FORM FOR ACADEMIC YEAR: 2023 -2024</h4>
      <h6 className='text-center'>FOR CLASS PRE - NURSERY</h6>
      <p className='text-center'>If any of the particular given below found incorrect or incomplete,<br/>
      the admission form for admission will be rejected</p>
        </div>
        <div className="img-container" style={{maxWidth: '300px'}}>
            <div className="bg-light  text-center" style={{padding: '1.8rem 0.7rem'}}>
                <p>PLEASE PASTE<br/> A LATEST<br/> PHOTOGRAPH<br/> OF THE<br/> APPLYING<br/> CANDIDATE.</p>
            </div>
        </div>
      </div>
      {loading ? (
                <p>Loading formData...</p>
            ) : (
      <div className='form-fill'>
          <form className='form-inline'>
              <div className='row'>
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name" >Name:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.name || '' } readOnly  />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="date_of_birth">Date of Birth:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.dob.slice(0, 10) || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label'>Gender:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.gender || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label'>Religion:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  className="form-control"  value={formData.religion || '' } readOnly  />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Nationality:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.nationality || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Caste:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.caste || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label'>Category:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.category || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label'>Language:</label>
                  <div className='col-sm-6'>
                      <input  style={inputStyle}  type="text" className="form-control" value={formData.language || '' } readOnly />
                      </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Name:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.fatherName || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Qualification:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control"value={formData.qualification || '' } readOnly/>
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Designation:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.designation || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Occupation:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  className="form-control" value={formData.occupation || '' } readOnly />
              </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Contact No:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="number" className="form-control" value={formData.mobNo || '' } readOnly  />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Whatsapp No:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="number" className="form-control" value={formData.whatsappNo || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Mother Name:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.motherName || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Mother Qualification:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.mqualification || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Mother Occupation:</label>
                  <div className='col-sm-6'>
                  <input style={inputStyle} className="form-control" value={formData.moccupation || '' } readOnly />
              </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Mother Mobile No:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="number" className="form-control" value={formData.mmobNo || '' } readOnly />
                  </div>
              </div>
              </div>

              <div className="form-group row">
                  <label style={labelStyle}  className='lcol col-form-label' htmlFor="student_name">Mother Whatsapp No:</label>
                  <div className='col'>
                  <input  style={inputStyle}  type="number" className="form-control" value={formData.mwhatsappNo || '' } readOnly />
                  </div>
              </div>
  
              <div className="form-group row">
                  <label style={labelStyle}  className='lcol col-form-label' htmlFor="address">Reason:</label>
                  <div className='col'>
                  <textarea style={inputStyle} className="form-control" value={formData.reason || '' } readOnly rows={2}></textarea>
              </div>
              </div>
  
              <div className="form-group row mb-5">
                  <label style={labelStyle}  className='lcol col-form-label' htmlFor="address">Address:</label>
                <div className='col'>
                  <textarea style={inputStyle} className="form-control" value={formData.address || '' } readOnly rows={2}></textarea>
              </div>
              </div>

              <div className="form-group row mt-5">
                <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="address">Details of Siblings/ Real brother or sister:</label>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Age</th>
                            <th>Class</th>
                            <th>Section</th>
                        </tr>
                    </thead>
                <tbody>
                {Data.map((item, index) => (
                <tr key={index}>
                    <td>{item.sName || '' } </td>
                    <td>{item.sAge || '' } </td>
                    <td>{item.sClass || '' } </td>
                    <td>{item.sSection || '' } </td>
                </tr>
                ))}
                </tbody>
                </table>
              </div>
              <div className="container my-5" style={{border: '2px solid black'}}>
                <div className="row justify-content-center">
                <div className="col-md-12 my-1">
                <div className="bg-light text-center" style={{ padding: '10rem'}}>
                    <p>PLEASE PASTE A RECENT PHOTOGRAPH</p>
                    <p>OF THE FAMILY OF THE APPLYING CANDIDATE</p>
                    <p><span>(ONLY THE PARENTS WITH THE APPLYING CANDIDATE)</span></p>
                </div>
                </div>
                </div>
                </div>
                </div>
          </form>
              </div>
              )}
      <center >
                {formData && formData.name && (
                    <QRCode
                    title="Admission form"
                    value={qrCodeValue}
                    bgColor={formData.back || 'white'}
                    fgColor={formData.fore || 'black'}
                    size={formData.size || 100}
                    />
                )}
            </center>
            <div style={{textAlign: 'left' , borderTop: '1px solid black', display:'inline-block', marginRight:'10rem' }}>
                <h6>Signature Of Mother (Guardian)</h6>
            </div>
            <div style={{textAlign: 'right' , borderTop: '1px solid black', display:'inline-block', marginLeft:'6rem'}}>
                <h6>Signature Of Father (Guardian)</h6>
            </div>
      </div>
    );
  });