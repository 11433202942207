import React, { useState, useEffect } from 'react';
import videoplayback from '../Images/VID_20240304113049.mp4'
import '../App.css'

export default function VideoGallery() {
  const[video, setVideo] = useState([]);

    useEffect(() => {
      fetch(`/api/VideoGallery`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setVideo(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
    }, []); 

  return (
    <>
    <div className='container shadow-lg p-3 mb-5 bg-white rounded'>
    <video className='carouselVideo responsive'controls autoPlay muted>
    <source src={videoplayback} type="video/mp4" width="200" height="100" />
    </video>
    </div>
    <div className="container my-5 py-5 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded">
    <div className="csection-title">
    <h2>Discover Our Engaging Video Gallery</h2>
    <p>A Visual Journey Through Memorable Moments at Apex</p>
    </div>
    <div className="row">
    {video.map((video, index) => 
    <div key={index} className={`col-md-4   ${index === 0 ? 'active' : ''}`}>
      <div className="image-containers shadow p-3 mb-5 bg-white rounded">
    <iframe width="100%" height="175" src={video.VideoUrl} title={video.Vtitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className="embed-responsive-item" allowFullScreen></iframe>
      </div>
    </div>
    )}
    </div>
</div>

    </>
  )
}
