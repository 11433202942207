import React from 'react'
import { Link } from 'react-router-dom';
import BuildingCertificate from '../Images/Building Safety Certifiacte.PDF'
import DIOS from '../Images/DIOS report.PDF'
import FIRE_SAFETY from '../Images/Fire Safety Certificate.PDF'
import NOC_COPY from '../Images/TRUST DEAD-1.PDF'
import Recognition_Copy from '../Images/BSA certificate.PDF'
import Sanitation_Certificate from '../Images/Sanitation certificate.PDF'

export default function Disclosure() {
  return (
<>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>CBSE Mandatory Disclosure</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Unlocking Transparency</h2>
  <p>Empowering You with Information and Insight</p>
</div>
<div className="Disclosure-table">
    <h5 className="Disclosure-heading">A: GENERAL INFORMATION ﻿</h5>
  <div className="Disclosure-row header-row">
    <div className="SlNo-cell">SL No.</div>
    <div className="Disclosure-cell">INFORMATION</div>
    <div className="Disclosure-cell">DETAILS</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">1</div>
    <div className="Info-cell">NAME OF THE SCHOOL</div>
    <div className="InfoDesc-cell">Apex International School</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">2</div>
    <div className="Info-cell">AFFILIATION NO ( IF APPLICABLE)</div>
    <div className="InfoDesc-cell">2133343</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">3</div>
    <div className="Info-cell">SCHOOL CODE ( IF APPLICABLE)</div>
    <div className="InfoDesc-cell">71548</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">4</div>
    <div className="Info-cell">COMPLETE ADDRESS WITH PIN CODE</div>
    <div className="InfoDesc-cell">Firozpur Kaiserganj Bahraich, Uttar Paradesh</div>
  </div>
  {/* <div className="Disclosure-row">
    <div className="SlNo-cell">5</div>
    <div className="Info-cell">PRINCIPAL NAME & QUALIFICATION</div>
    <div className="InfoDesc-cell">Mr. Rajesh Mishra ( M.A., B.Ed.)</div>
  </div> */}
  <div className="Disclosure-row">
    <div className="SlNo-cell">5</div>
    <div className="Info-cell">SCHOOL MAIL ID</div>
    <div className="InfoDesc-cell">apexinternationalschool@gmail.com</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">6</div>
    <div className="Info-cell">CONTACT DETAILS (LANDLINE/MOBLIE)</div>
    <div className="InfoDesc-cell">+91-8400314141</div>
  </div>
</div>
<div className="Disclosure-table">
<h5 className="Disclosure-heading">B: DOCUMENTS AND INFORMATION ﻿</h5>
  <div className="Disclosure-row header-row">
    <div className="SlNo-cell">SL No.</div>
    <div className="Disclosure-cell">INFORMATION</div>
    <div className="Details-cell">DETAILS</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">1</div>
    <div className="Discrip-cell">COPIES OF AFFILIATION / UPGRADATION LETTER AND RECENT EXTENSION OF AFFILATION</div>
    <div className="Details-cell"><Link to='#' target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">2</div>
    <div className="Discrip-cell">COPIES OF SOCIETIES /TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE AS APPLICABLE</div>
    <div className="Details-cell"><Link to='#' target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">3</div>
    <div className="Discrip-cell">COPY OF NO OBJECTION CERTIFICATE AS APPLICABLE. IF APPLICABLE BY THE STATE GOVTE/UT</div>
    <div className="Details-cell"><Link to={NOC_COPY} target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">4</div>
    <div className="Discrip-cell">COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT. 2009 AND ITS RENEWAL IF APPLICABLE</div>
    <div className="Details-cell"><Link to={Recognition_Copy} target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">5</div>
    <div className="Discrip-cell">COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</div>
    <div className="Details-cell"><Link to={BuildingCertificate} target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">6</div>
    <div className="Discrip-cell">COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</div>
    <div className="Details-cell"><Link to={FIRE_SAFETY} target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">7</div>
    <div className="Discrip-cell">COPY OF THE DEO CERTIFICATION SUBMITIED BY THE SCHOOL FOR AFFILIATON/UPGRADATION BY SCHOOL</div>
    <div className="Details-cell"><Link to={DIOS} target='_blank' className='nav-link'>View Details</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">8</div>
    <div className="Discrip-cell">COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</div>
    <div className="Details-cell"><Link to={Sanitation_Certificate} target='_blank' className='nav-link'>View Details</Link></div>
  </div>
</div>
<div className="Disclosure-table">
<h5 className="Disclosure-heading">C: RESULT AND ACADEMICS ﻿</h5>
  <div className="Disclosure-row header-row">
    <div className="SlNo-cell">SL No.</div>
    <div className="Disclosure-cell">INFORMATION</div>
    <div className="Details-cell">DETAILS</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">1</div>
    <div className="Discrip-cell">FEE STRUCTURE OF THE SCHOOL</div>
    <div className="Details-cell"><Link to='/FeeStructure' target='_blank' className='nav-link'>View Detail's</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">2</div>
    <div className="Discrip-cell">ANNUAL ACADEMIC CALANDER</div>
    <div className="Details-cell"><Link to='#'target='_blank' className='nav-link'>View Detail's</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">3</div>
    <div className="Discrip-cell">LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC )</div>
    <div className="Details-cell"><Link to='#' target='_blank' className='nav-link'>View Detail's</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">4</div>
    <div className="Discrip-cell">LIST OF PARENTS TEACHERS ASSOCIATION (PTA ) MEMBERS</div>
    <div className="Details-cell"><Link to='#' target='_blank' className='nav-link'>View Detail's</Link></div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">5</div>
    <div className="Discrip-cell">LAST THREE YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABLILITY</div>
    <div className="Details-cell"><Link to='#' target='_blank' className='nav-link'>View Detail's</Link></div>
  </div>
</div>
<div className="Disclosure-table">
<h5 className="Disclosure-heading">D: STAFF (TEACHING) ﻿</h5>
  <div className="Disclosure-row header-row">
    <div className="SlNo-cell">SL No.</div>
    <div className="Disclosure-cell">INFORMATION</div>
    <div className="Disclosure-cell">DETAILS</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">1</div>
    <div className="SInfo-cell">PRINCIPAL</div>
    <div className="SDetails-cell">1</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">2</div>
    <div className="SInfo-cell">TOTAL NO OF TEACHERS</div>
    <div className="SDetails-cell">100+</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">-</div>
    <div className="SInfo-cell">PGT</div>
    <div className="SDetails-cell">11</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">-</div>
    <div className="SInfo-cell">TGT</div>
    <div className="SDetails-cell">06</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">-</div>
    <div className="SInfo-cell">PRT</div>
    <div className="SDetails-cell">23</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">3</div>
    <div className="SInfo-cell">TEACHER SECTION RATIO</div>
    <div className="SDetails-cell">1:1.37</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">4</div>
    <div className="SInfo-cell">DETAILS OF SPECIAL EDUCATOR</div>
    <div className="SDetails-cell">01</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">5</div>
    <div className="SInfo-cell">DETAILS OF COUNSELLOR AND WELNESS TEACHER</div>
    <div className="SDetails-cell">01</div>
  </div>
</div>
<div className="Disclosure-table">
<h5 className="Disclosure-heading">E: SCHOOL INFRASTUCTRE ﻿</h5>
  <div className="Disclosure-row header-row">
    <div className="Disclosure-cell">SL No.</div>
    <div className="Disclosure-cell">INFORMATION</div>
    <div className="Disclosure-cell">DETAILS</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">1</div>
    <div className="SIInfo-cell">TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)</div>
    <div className="SIDetail-cell">8360 Sq. Mt.</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">2</div>
    <div className="SIInfo-cell">TOTAL BUILT UP AREA OF THE SCHOOL (IN SQUARE MTR)</div>
    <div className="SIDetail-cell">3627.01 Sq. Mt.</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">3</div>
    <div className="SIInfo-cell">NO AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)</div>
    <div className="SIDetail-cell">No. of Class Room 25 and Room Size 48.79 Sq. Mt.</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">4</div>
    <div className="SIInfo-cell">NO AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)</div>
    <div className="SIDetail-cell">No. of Laboraturies 6 Total Area of Laboraturies 58.5 Sq. Mt.</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">5</div>
    <div className="SIInfo-cell">INTERNET FACILITY (Y/N)</div>
    <div className="SIDetail-cell">Yes</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">6</div>
    <div className="SIInfo-cell">NO OF GIRLS TOILETS</div>
    <div className="SIDetail-cell">09</div>
  </div>
  <div className="Disclosure-row">
    <div className="SlNo-cell">7</div>
    <div className="SIInfo-cell">NO OF Boys TOILETS</div>
    <div className="SIDetail-cell">09</div>
  </div>
  <div className="Disclosure-row">
    <div className="SIInfo-cell">LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</div>
  </div>
</div>
</div>
</>
  )
}
