import React, { useState } from 'react';

export default function DocumentDwnld() {
    const[className, setClassName] = useState('');
    const[docTitle, setDocTitle] = useState('');
    const[upldDoc, setUpldDoc] = useState(null);
    const[isSubmitting, setIsSubmitting] = useState(false);
    const[showModal, setShowModal] = useState(false);
    const[successMessage, setSuccessMessage] = useState('');

    const handleclassNameChange = (e) =>{
      setClassName(e.target.value);
    }

    const handledocTitleChange = (e) =>{
      setDocTitle(e.target.value);
    }

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    const handleupldDocChange = (e) =>{
      const selectedPdf = e.target.files[0];
      if(selectedPdf){
        setUpldDoc(selectedPdf);
        const reader = new FileReader();
        reader.onload = (event) => {
        };
        reader.readAsDataURL(selectedPdf)
      }
      else{
        setUpldDoc(null)
      }
    }

    const handleSubmit = async (e) =>{
      console.log('Handle PDF Change Called');
      e.preventDefault();
      if(isSubmitting){
        return;
      }
      setIsSubmitting(true);
      setSuccessMessage('Document Uploaded successfully.');
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 2000); 
      const formData = new FormData();
      formData.append('className', className);
      formData.append('docTitle', docTitle);
      formData.append('pdf', upldDoc);
      try {
          const response = await fetch(`/api/Admin/Uploads/Documents`, {
            method: 'POST',
            body: formData,
          });
      
          if (response.ok) {
            setClassName('');
            setDocTitle('');
            setUpldDoc(null);
          } else {
          }
        } catch (error) {
          console.error('Error uploading File:', error);
        }
        setIsSubmitting(false);
    };

  return (
  <>
{showModal && (
    <div className="modal-backdrop show"></div>
  )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
              <h5 className="modal-title">Success Message</h5>
            </div>
            <div className="modal-body">
              {successMessage && <p className='text-success'>{successMessage}</p>}
            </div>
          </div>
        </div>
    </div>
  <div className='container mt-5 '>
      <h2 className='text-center'>Upload Document</h2>
      <form className='form-container' onSubmit={handleSubmit}>
        <div>
          <label  className='form-label' htmlFor="className">Class Name:</label>
          <input  className='form-input' type="text" id="className" value={className}  onChange={handleclassNameChange} required />
        </div>
        <div>
          <label  className='form-label' htmlFor="docTitle">Document Title:</label>
          <input  className='form-input' type="text" id="docTitle" value={docTitle} onChange={handledocTitleChange} required />
        </div>
        <div>
          <label className='form-label'  htmlFor="pdf">Upload Document:<span className='span'> (in PDF Format)</span></label>
          <input className='form-input' type='file' id='pdf' value={null} onChange={handleupldDocChange}/>
        </div>
        <div className='mt-2'>
        <button className='form-submit' type="submit">Upload PDF</button>
        </div>
      </form>
    </div>
  </>
  )
}
