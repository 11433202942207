import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import schoolBuildingImage from '../Images/Economics B1-L1-030.jpg';
import founderImg from '../Images/founder.jpeg'
import directorImg from '../Images/Chairman.JPG';
import lab from '../Images/lab.jpeg';
import lib from '../Images/lib.jpg';
import smart from '../Images/503863_download_816950.png';
import student from '../Images/logo/777663.png';
import course from '../Images/logo/6213222.png';
import event from '../Images/logo/8683098.png';
import teacher from '../Images/logo/1310380.png';
import '../App.css'

export default function Feature() {

    useEffect(() => {
      // Initialize purecounter animations when the component mounts
      const counters = document.querySelectorAll('.purecounter');
      counters.forEach(counter => {
        const startValue = parseInt(counter.getAttribute('data-purecounter-start'));
        const endValue = parseInt(counter.getAttribute('data-purecounter-end'));
        const duration = parseInt(counter.getAttribute('data-purecounter-duration'));
  
        animateCounter(counter, startValue, endValue, duration);
      });
    }, []);
  
    const animateCounter = (counter, start, end, duration) => {
      // let currentTime = 0;
      const increment = Math.floor((end - start) / duration);
      // let currentValue = start;
      let startTime = null;
  
      const updateCounter = (timestamp) => {
        if (!startTime) {
          startTime = timestamp;
        }
    
        const elapsedTime = timestamp - startTime;
        if (elapsedTime >= duration * 500) {
          counter.innerText = end;
          return;
        }
    
        const value = Math.min(start + increment * (elapsedTime / (duration * 1000)), end);
        counter.innerText = Math.floor(value);
        requestAnimationFrame(updateCounter);
      };
    
      requestAnimationFrame(updateCounter);
    };

  return (
   <>
		<div className="elementor-widget-container my-4">
			<div className="tm-modern-heading">
				<div className="heading-primary-wrap">
			      <h2 className="heading-primary elementor-heading-title">Welcome to Apex International School</h2></div>
				<div className="heading-divider my-3"></div>
				<div className="heading-description-wrap">
			    <div className="heading-description">
				    <p  className="heading-description">reaching new heights, together</p></div>
		    </div>
		  </div>
		</div>

   <div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1200px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body px-4">
        <h5>A Warm Welcome To All Our Students, Staff, Parents And Visitors</h5>
        <p className="">
        Apex International School is a dedicated educational institution committed to providing quality education and fostering holistic development. Our mission is to nurture young minds, empower them with knowledge.
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i>Founded with a vision to create responsible and confident individuals.</li>
          <li><i className="bi bi-check-circle"></i>Apex International  School offers a nurturing environment where students can thrive academically.</li>
          <li><i className="bi bi-check-circle"></i>Our experienced and dedicated faculty members work tirelessly to inspire curiosity.</li>
        </ul>
        <p>
         The School's prime objective is to develop the overall personality of a child in a healthy and congenial atmosphere.
        </p>
        <Link to='/AboutUs' className='nav-link' ><p className="card-text"><small className="text-body-secondary">Learn More+</small></p></Link>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
      <img src={schoolBuildingImage} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
   <div className="mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
   <div className="col-md-4 d-flex align-items-center justify-content-center order-md-1 order-2">
      <img src={founderImg} className="img-fluid rounded-start chairman" alt="..." />
    </div>
    <div className="col-md-8 order-md-2 order-1">
      <div className="card-body px-4">
        <h5>Founder Message</h5>
        <p className="">Late Mr. Noor Hussain's legacy continues to inspire us at Apex International School in Uttar Pradesh, India</p>
        <p>His vision and dedication laid the foundation for our commitment to excellence in education. We honor his memory as we strive to nurture young minds and build a brighter future for generations to come.</p>
        <p className="card-text"><small className="text-body-secondary">Thank You</small></p>
        <p className="card-text">Late Mr. Noor Hussain</p>
      </div>
    </div>
    </div>
    </div>
   <div className="card mb-1 mt-3 bg-light text-dark" style={{ maxWidth: '1200px' }}>
  <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Chairman message</h5>
        <p className="card-text">
        I welcome you and your child to a new experience in education. We at Apex International  School, endeavour to provide a curriculum with a difference. One that balances excellence in learning with an all-round cultural, physical, mental and moral development. Our attempt is to make the most formative and beautiful years of our students’ lives a joy forever.
        </p>
        <p className="card-text">
        We try to build ocean liners with Indian anchors and ethical rudders. A new age CBSE school is welcoming all of you with open arms to revive those Indian Values which today seems to be outdated and has lost its sheen.
        </p>
        <p className="card-text"><small className="text-body-secondary">Thank You</small></p>
        <p className="card-text">Mr. Mashkoor Habib</p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center justify-content-center">
    <img src={directorImg} className="img-fluid rounded-start chairman" alt="..." />
    </div>
    </div>
</div>
</div>

{/* Testimonal Section */}

<div className="container counts mb-2 py-3 bg-light text-success" style={{ maxWidth: "1400px"}}>
  <div className="row counters">
    <div className="col-lg-3 col-6 text-center">
      <p className='mb-0'><img src={student} className="fetaureIcon" alt="..." /></p>
      <div className='d-flex justify-content-center'><span data-purecounter-start="0" data-purecounter-end="4000+" data-purecounter-duration="1" className="purecounter">4000</span><strong style={{marginTop: '2%'}}>+</strong></div>
      <p>Students</p>
    </div>
    <div className="col-lg-3 col-6 text-center">
      <p className='mb-0'><img src={course} className="fetaureIcon" alt="..." /></p>
      <div className='d-flex justify-content-center'><span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1" className="purecounter">20</span><strong style={{marginTop: '2%'}}>+</strong></div>
      <p>course</p>
    </div>
    <div className="col-lg-3 col-6 text-center">
      <p className='mb-0'><img src={event} className="fetaureIcon" alt="..." /></p>
      <span data-purecounter-start="0" data-purecounter-end="42" data-purecounter-duration="2" className="purecounter">42</span>
      <p>Event</p>
    </div>
    <div className="col-lg-3 col-6 text-center">
      <p className='mb-0'><img src={teacher} className="fetaureIcon" alt="..." /></p>
      <div className='d-flex justify-content-center'><span data-purecounter-start="0" data-purecounter-end="100+" data-purecounter-duration="3" className="purecounter">100+</span><strong style={{marginTop: '2%'}}>+</strong></div>
      <p>Teacher</p>
    </div>
  </div>
</div>

{/* School Facilities Section  */}

<div id="popular-courses" className="courses my-5">
  <div className="container aos-init aos-animate" data-aos="fade-up">
    <div className="section-title">
      <h2>Facilities</h2>
      <p>School Facilities</p>
    </div>

  <div className="row aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
      <div className="course-item">
        <img src={lab} className="img-fluid" alt="..." />
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Link to='/Laboratory' className='nav-link' ><h4>LABORATORIES</h4></Link>
          </div>
        <h3><a href="course-details.html">School Lab</a></h3>
        <p>Laboratories are essential part of modern education to enable students to experience and visualize concept. The school has modern laboratories for science and computer with all the necessary items for items for experiments and help in teaching.</p>
        <div className="trainer d-flex justify-content-between align-items-center">
        <div className="trainer-profile d-flex align-items-center">
        <img src='' className="img-fluid" alt="" />
        <span className='text-white'>APEX</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
      <div className="course-item">
        <img src={lib} className="img-fluid" alt="..." />
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Link to='/' className='nav-link' ><h4>LIBRARY</h4></Link>
          </div>
        <h3><a href="course-details.html">School Library</a></h3>
        <p>The school has well stocked library. Besides books related to curriculum and competitive examination, the school library also contain books of science, history, grammer etc. which are very essential for student's all round development.</p>
        <div className="trainer d-flex justify-content-between align-items-center">
          <div className="trainer-profile d-flex align-items-center">
            <img src="assets/img/trainers/trainer-2.jpg" className="img-fluid" alt="" />
            <span  className='text-white'>APEX</span>
          </div>
                </div>
        </div>
      </div>
    </div> 

    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
      <div className="course-item">
        <img src={smart} className="img-fluid" alt="..." />
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
           <Link to='/SmartClass' className='nav-link' ><h4>SMART CLASSES</h4></Link>
          </div>
          <h3><a href="course-details.html">School Smart Classes</a></h3>
          <p>Apex International School is the first school in the region with a new age technology to enhance the learning skills through smart class. An EdTech-upgraded classroom that enhances the teaching and learning process for both the teachers and the students.</p>
          <div className="trainer d-flex justify-content-between align-items-center">
            <div className="trainer-profile d-flex align-items-center">
              <img src="assets/img/trainers/trainer-3.jpg" className="img-fluid" alt="" />
              <span  className='text-white'>APEX</span>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>
</div>
   </>
  );
}
