import React, { useState, useEffect } from 'react';
import '../CSS/Admin_Home.css';

export default function ViewNews() {
    const [news, setNews] = useState([]);
  
    useEffect(() => {
      fetch(`/api/ViewNews`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setNews(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
    }, []);     

    return (
      <>
        <div className='d-felx mx-3' >
        <h1 className='text-center'>View Notice</h1>
      
        <div className="shadow mb-5 rounded">
        <table className='table table-nowrap mb-0 mt-3'>
          <thead className='table-primary'>
            <tr>
              <th>Title</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody className='text-center'>
          {news.map((item, index) => (
                <tr key={index} className='text-center'>
                  <td>{item.newsTitle}</td>
                  <td>{item.startDate.slice(0, 10)}</td>
                  <td>{item.endDate.slice(0, 10)}</td>
                </tr>
              ))}
          </tbody>
        </table>
        </div>
        </div>
      </>
    );
}
