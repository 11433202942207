import React, { useState } from 'react';

export default function TcUpldFrm() {

    const [srNo, setSrNo] = useState('');
    const [doBirth, setDoBirth] = useState('');
    const [upldTc, setUpldTc] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const[showModal, setShowModal] = useState(false);
    const[successMessage, setSuccessMessage] = useState('');

    const handlesrNoChange = (e) => {
      setSrNo(e.target.value);
    };
    
    const handledoBirthChange = (e) => {
      setDoBirth(e.target.value);
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };
    
    
    const handleupldTcChange = (e) => {
      const selectedPdf = e.target.files[0];
      if (selectedPdf) {
        setUpldTc(selectedPdf);
    
        const reader = new FileReader();
        reader.onload = (event) => {
        };
        reader.readAsDataURL(selectedPdf);
      } 
      else {
        setUpldTc(null);  
      }
    };
    
    const handleSubmit = async (e) => {
      console.log('handlePDFChange called');
      e.preventDefault();
      if (isSubmitting) {
        return; 
      }
      setIsSubmitting(true); 
      setSuccessMessage('TC Uploaded successfully.');
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 2000); 

      const formData = new FormData();
      formData.append('SrNo', srNo);
      formData.append('DOB', doBirth);
      formData.append('pdf', upldTc);
    
      try {
        const response = await fetch(`/api/Admin/Uploads/TransferCertificate`, {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          setSrNo('');
          setDoBirth('');
          setUpldTc(null);
        } else {
        }
      } catch (error) {
        console.error('Error uploading PDF:', error);
      }
      setIsSubmitting(false);
    }
  return (
   <>
{showModal && (
    <div className="modal-backdrop show"></div>
  )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
              <h5 className="modal-title">Success Message</h5>
            </div>
            <div className="modal-body">
              {successMessage && <p className='text-success'>{successMessage}</p>}
            </div>
          </div>
        </div>
  </div>
   <div className='container mt-5'>
      <h2 className='text-center'>Upload Transfer Certificate (TC)</h2>
      <form className='form-container' onSubmit={handleSubmit}>
        <div>
          <label  className='form-label' htmlFor="title">Sr No:</label>
          <input  className='form-input' type="text" id="title" value={srNo}  onChange={handlesrNoChange} required />
        </div>
        <div>
          <label  className='form-label' htmlFor="dob">Date Of Birth: <span className='span'> (mm/dd/yyyy)</span></label>
          <input  className='form-input' type="Date" id="dob" value={doBirth} onChange={handledoBirthChange} required />
        </div>
        <div>
          <label className='form-label'  htmlFor="pdf">Upload Tc:<span className='span'> (in PDF Format)</span></label>
          <input className='form-input' type='file' id='pdf' value={null} onChange={handleupldTcChange}/>
        </div>
        <div className='mt-2'>
        <button className='form-submit' type="submit">Upload PDF</button>
        </div>
      </form>
    </div>
   </>
  )
}
